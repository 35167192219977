@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::after,
::before {
  box-sizing: border-box;
}
::selection {
  background-color: yellow;
  color: rgb(8, 13, 165);
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  /* -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; */
}

.css-zxdg2z {
  background: white;
}
.active {
  background-color: blue;
}
.gradient-yellow-blue {
  background: linear-gradient(to right, #fdeb71, #020295);
}

.border-yellow {
  border-color: #fdeb71;
}

.css-6d4qw9-MuiPaper-root-MuiAppBar-root {
  box-shadow: none;
}
.css-2dum1v-MuiDrawer-docked {
  display: none !important;
}
.app-header {
  background-color: "#fff";
  border: "20px solid #fafafa";
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Raleway:wght@100;300;400&display=swap"); */

html {
  font-size: 100%;
} /*16px*/

:root {
  /* grey */
  --yellow-bg: #e4d829;
  --bg-color: #eceafc;
  --bg-white: rgb(255, 255, 255);

  /* fonts  */
  --headingFont: "Roboto Condensed", Sans-Serif;
  --bodyFont: "Open Sans", sans-serif;

  /* rest of the vars */
  --backgroundColor: var(-- --bg-color);
}

body {
  font: 400 1.75 var(--bodyFont);
  background-color: #feffee;
  max-height: 100vh;
  overflow: hidden;
  padding-right: 10px;
}

.h-height {
  height: 104%;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
.Toastify__toast {
  text-transform: capitalize;
  margin-top: 50px;
}
input[type="radio"] {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #ffffff;
  border-color: #d1d5db;
  color: #374151;
}
ul {
  list-style: none;
}

.dropdown-profile {
  position: absolute;
  top: 4.125rem;
  right: 2px;
  background-color: rgb(214, 216, 221);
  border-radius: 0px 0px 5px 5px;
  padding: 10px 20px;
}

.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms, transform 200ms;
}
/* Styles for the sidebar element */
.sidebar {
  background-color: white;
  /* transition properties */
  transition-duration: 0.3s;
  transition-property: transform, background-color, border-right;
}

.sidebar-enter {
  transform: translateX(100%);
  opacity: 0;
}

.sidebar-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.sidebar-exit {
  transform: translateX(-100%);
  transition: transform 300ms ease-out;
}

.sidebar-exit-active {
  transform: translateX(0);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.bg_signup {
  background: linear-gradient(to right, #dfed42, #0b34aedb);
}
.PhoneInput {
  border: none;
  padding: 0%;
}
.PhoneInput--focus {
  border: none;
  padding: 0%;
}

/* Auth */
.login {
  background-image: url(../src/assets/images/login.svg);
  background-repeat: no-repeat, repeat;
  height: 100vh;
  background-color: var(--bg-white);
}
@media (max-width: 768px) {
  .login {
    background-image: url(../src/assets/images/loginmobile.svg);
    background-repeat: no-repeat, repeat;
  }
}
#register {
  background-image: url(../src/assets/images/rg.svg);
  background-repeat: no-repeat, repeat;
  height: 100vh;
  width: 100%;
  background-color: var(--yellow-bg);
}
@media (max-width: 768px) {
  #register {
    background-image: url(../src/assets/images/regmobile.svg);
    background-repeat: no-repeat, repeat;
    background-size: contain;
  }
}
.top-margin {
  margin-top: 50px;
}
.position-path {
  margin-left: 944px;
  margin-bottom: 43px;
  height: 230%;
}
.path-position {
  margin-left: 660px;
  margin-bottom: 90px;
  height: 250%;
}
.bg-profile {
  background: linear-gradient(90deg, #e5d701 0%, #022674 100%);
}
.bg-welcome {
  background: linear-gradient(90deg, #022674 0%, #e5d701  100%);
}
.bg-top-profile {
  margin-top: 160px;
  margin-left: 60px;
}
.me-top {
  margin-top: -120px;
  height: 135px;
  border-color: 1px solid red !important;
}
.abt-top {
  margin-top: 120px;
}
.about-top {
  margin-top: 100px;
}
.my-profile {
  margin-top: 181px;
}
.m-profile {
  margin-top: 181px;
  margin-left: 18px;
}
.upload-avatar {
    margin-top: 3px;
    margin-left: -57px;
}
.select-none {
  user-select: none;
}

@media (max-width: 768px) {
  .my-profile {
    margin-top: 181px;
    margin-left: 13px;
  }
  .abt-top {
    margin-top: 345px;
  }
}
/* input[type="file"] {
  display: none;
} */
.custom-file-upload {
  display: inline-block;
  padding: 8px 8px;
  cursor: pointer;
  background: rgb(5, 3, 97);
  border-radius: 50%;
  margin-left: 60px;
  margin-top: -70px;
}
.custom-file-banner {
  display: inline-block;
  padding: 8px 8px;
  cursor: pointer;
  background: rgb(5, 3, 97);
  border-radius: 50%;
  margin-left: 180px;
  margin-top: -20px;
}
.p-left {
  margin-left: 9px;
}
.opt_up {
  margin-right: 4.563rem;
}
.text-hue {
  background: linear-gradient(to right, #007bff, #ffd700, #fff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
